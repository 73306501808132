<template>
  <div class="calendar-block__container" v-if="!appError">
    <div v-if="isLoaded" class="calendar" :class="{'calendar--hidden': !showContent}">
      <ul v-if="filterTags" v-show="!miniCalendar" class="filter">
        <FilterTag
          v-for="tag in filterTags"
          :tag="tag"
          @onFilterClick="onFilterClick"
        />
      </ul>

      <CalendarList
        :filteredCalendarEvents="filteredCalendarEvents"
        :translations="translations"
        :culture="culture"
        :miniCalendar="miniCalendar"
      />
    </div>
    <Spinner v-else/>
  </div>

  <section class="container error-message" v-else>
    <p>{{ errorMessage }}</p>
  </section>
  
</template>

<script>
import * as calendarService from "../_js/services/calendarService";
import FilterTag from "./components/filterTag.vue";
import CalendarList from "./components/calendarList.vue";
import Spinner from '@shared/spinner/spinner.vue';

export default {
  name: "app",
  components: {
    Spinner,
    FilterTag: FilterTag,
    CalendarList: CalendarList,
  },
  data() {
    return {
      calendarEvents: [],
      filterTags: [],
      translations: {},
      culture: "",
      isLoaded: false,
      appError: false,
      errorMessage: "",
      filterArray: [],
      showContent: false,
      miniCalendar: false,
    };
  },
  mounted() {
    this.miniCalendar = this.getElementValue("miniCalendar");
    calendarService.getCalendarData(this.miniCalendar)
    .then((data) => {
      this.calendarEvents = data.SingleEvents;
      this.filterTags = data.CompleteTags;
      this.translations = data.Translations;
      this.culture = data.Culture;
      
      // Hide filter heading if no tags are available
      if (!this.filterTags.length) {
        document.querySelector('.calendar-block__filter-heading').style.display = 'none';
      }

      if (this.calendarEvents.length === 0) {
        this.appError = true;
        this.errorMessage = this.translations.ErrorMessageNoCalendars;
      }
      
      // Set isLoaded to true after 1 second to prevent spinner from flashing
      setTimeout(() => {
        this.isLoaded = true;
        document.querySelector('.calendar-block__filter-heading').hidden = this.miniCalendar;
      }, 1000);
    })
    .catch(() => {
      this.appError = true;
      this.errorMessage = this?.translations?.ErrorMessageFailed;
    });
  },
  computed: {
    filteredCalendarEvents: function () {
      let filter = this.filterArray;

      // If no filters are selected, return all calendar items
      if (filter.length === 0) {
        return this.getGroupedCalendarEvents(this.calendarEvents);
      }
      
      // If any of the tags in the filter array is included in the calendar item, include the item
      return this.getGroupedCalendarEvents(this.calendarEvents.filter(calendar => {
        return calendar.CompleteTags.some(tag => filter.includes(tag.TagId));
      }));

    }
  },
  methods: {
    onFilterClick: function (filterTag) {
      if (!this.filterArray.includes(filterTag)) {
        this.filterArray.push(filterTag);
      } else {
        for (let i = 0; i < this.filterArray.length; i++) {
          if (this.filterArray[i] === filterTag) {
            this.filterArray.splice(i, 1)
            i--;
          }
        }
      }

    },
    getElementValue: function(id) {
      let element = document.getElementById(id);
      if (element) {
        return element.value === "True" ? true : false;  
      }
    },
    getGroupedCalendarEvents: function (calendarList) {
      // If miniCalendar is true, return all calendar items in the current month. Always only three items. 
      if(this.miniCalendar) {
        const currentMonthName = new Date().toLocaleString(this.culture, { month: 'long' });

        return {
          [currentMonthName]: calendarList
        };
      }

      //Group events (and split if recurring) by month
      return calendarList.reduce((group, calenderEvent) => {

        const {Start, PeriodEndDate, IsRecurring} = calenderEvent;
        const startDate = new Date(Start);
        const periodEndDate = new Date(PeriodEndDate);

        if (IsRecurring) {
          const endDate = new Date(periodEndDate.getFullYear(), periodEndDate.getMonth() + 1, 0);
          let i = 1;

          for (let currentDate = startDate; currentDate <= endDate; currentDate.setMonth(currentDate.getMonth() + 1)) {
            //If recurring is 12 months, prevent next years 12th month from being added to this years calendar month
            if (i !== 12) {
              const currentMonthName = currentDate.toLocaleString(this.culture, {month: 'long'})
              group[currentMonthName] = group[currentMonthName] || [];
              let calendarEventCopy = Object.assign({}, calenderEvent);
              group[currentMonthName].push(calendarEventCopy);
              i++;
            }
          }
        } else {
          const monthName = startDate.toLocaleString(this.culture, {month: 'long'})
          group[monthName] = group[monthName] || [];
          group[monthName].push(calenderEvent);
        }
        for (let month in group) {
          if (group.hasOwnProperty(month)) {
            group[month].sort((a, b) => {
              return (a.IsRecurring === b.IsRecurring) ? 0 : a.IsRecurring ? -1 : 1;
            });
          }
        }
        return group;
      }, {});
    }
  },
  watch: {
    isLoaded: {
      handler(newValue) {
        setTimeout(() => {
          this.showContent = newValue;
        });
      },
      deep: true
    }
  }
  
};
</script>
